// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["r51EX_KT4", "lePDCxTd6"];

const serializationHash = "framer-39nym"

const variantClassNames = {lePDCxTd6: "framer-v-1x62oyr", r51EX_KT4: "framer-v-xue010"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "r51EX_KT4", "Variant 2": "lePDCxTd6"}

const getProps = ({cTATEAM, height, id, width, ...props}) => { return {...props, bAOipNjQ4: cTATEAM ?? props.bAOipNjQ4, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "r51EX_KT4"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;cTATEAM?: React.MutableRefObject<HTMLElement>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, bAOipNjQ4, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "r51EX_KT4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear43ce2k = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 600)
})

useOnVariantChange(baseVariant, {default: onAppear43ce2k})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce={false} __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__targets={[{ref: bAOipNjQ4, target: "animate"}]} __framer__threshold={0} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xue010", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"r51EX_KT4"} ref={ref ?? ref1} style={{backdropFilter: "blur(2px)", backgroundColor: "rgba(255, 255, 255, 0.6)", borderBottomLeftRadius: 80, borderBottomRightRadius: 80, borderTopLeftRadius: 80, borderTopRightRadius: 80, boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)", opacity: 1, WebkitBackdropFilter: "blur(2px)", ...style}} variants={{lePDCxTd6: {opacity: 0}}} {...addPropertyOverrides({lePDCxTd6: {__targetOpacity: 0, "data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 512, intrinsicWidth: 389.5, pixelHeight: 1024, pixelWidth: 779, positionX: "center", positionY: "top", sizes: `calc(${componentViewport?.width || "100vw"} - 8px)`, src: "https://framerusercontent.com/images/pZS4ok0mH6NF47d06XEpaeM32JY.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/pZS4ok0mH6NF47d06XEpaeM32JY.png 779w"}} className={"framer-1qsphoq"} data-framer-name={"image"} layoutDependency={layoutDependency} layoutId={"r51EX_KT4eNiwIG08k"} style={{borderBottomLeftRadius: 80, borderBottomRightRadius: 80, borderTopLeftRadius: 80, borderTopRightRadius: 80, opacity: 0.75}}/></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-39nym.framer-ofwd5d, .framer-39nym .framer-ofwd5d { display: block; }", ".framer-39nym.framer-xue010 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 80px; justify-content: center; padding: 4px; position: relative; width: 80px; }", ".framer-39nym .framer-1qsphoq { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-39nym.framer-xue010 { gap: 0px; } .framer-39nym.framer-xue010 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-39nym.framer-xue010 > :first-child { margin-top: 0px; } .framer-39nym.framer-xue010 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"lePDCxTd6":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"bAOipNjQ4":"cTATEAM"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCFwFfUiD5: React.ComponentType<Props> = withCSS(Component, css, "framer-39nym") as typeof Component;
export default FramerCFwFfUiD5;

FramerCFwFfUiD5.displayName = "avatarTeam";

FramerCFwFfUiD5.defaultProps = {height: 80, width: 80};

addPropertyControls(FramerCFwFfUiD5, {variant: {options: ["r51EX_KT4", "lePDCxTd6"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, bAOipNjQ4: {title: "CTATEAM", type: ControlType.ScrollSectionRef}} as any)

addFonts(FramerCFwFfUiD5, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})